import "./NotFoundPage.scss";

function NotFoundPage() {
  return (
    <div className="not-found">
      <h1>404</h1>
      <p>That page could not be found</p>
    </div>
  );
}

export default NotFoundPage;
